import React, { useRef, createContext, useEffect, useReducer } from 'react';
import { arrayOf, bool, func, node, object, oneOfType } from 'prop-types';
import { useSelector } from 'react-redux';

// Import shaka-player
import shaka from 'shaka-player/dist/shaka-player.ui.js';

// Import actions
import {
	reducer,
	setBuffering,
	setIsReady,
	setSessionIntervalTime,
	setIsEnded,
	setIsPaused,
	setPlaylistData,
	setPlayerError
} from './store';

// Import helpers
import {
	calculateVideoIntervalTime,
	getManifestUri
} from 'components/context/player/helpers';
import { getUserAgentInfo } from 'helpers';
import { EVENTS_NAMES } from 'helpers/variables';
import { PLAYER_INITIAL_STATE } from './helpers';
import { getPlayerConfiguration } from 'components/context/player/shakaPlayer/helpers.js';

// Import utilities
import { useInterval } from 'components/utilities';

// Import components
import { Video } from 'components/elements/molecules/video_banners/styles';

const { ERROR, BUFFERING, PLAY } = EVENTS_NAMES;

// Create context
export const PlayerContext = createContext();

const PlayerProvider = ({
	children,
	data,
	nextProduct,
	isMuted,
	isActive,
	deleteVideoSession,
	updateVideoSession,
	toggleSound
}) => {
	const { isSafari } = getUserAgentInfo();

	const videoRef = useRef(null);
	const playerRef = useRef(null);

	// ---- state redux ----
	const { areAnimationsAvailable } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	// ---- state reducer ----
	const [state, stateDispatch] = useReducer(reducer, PLAYER_INITIAL_STATE);

	const { playlistData } = state;

	const {
		playlist,
		configuration: { videoSession }
	} = data;

	const { videoSessionId, sessionIntervalTime } = videoSession;

	const onError = () => {
		setPlayerError()(stateDispatch);
	};

	const onBufferStateChange = () => {
		const player = playerRef.current;
		const buffering = player.isBuffering();

		setBuffering(buffering)(stateDispatch);
	};

	const onLoad = () => {
		const player = playerRef.current;
		videoRef.current.addEventListener(PLAY, handlePlay);
		player.addEventListener(ERROR, onError);
		player.addEventListener(BUFFERING, onBufferStateChange);

		player.configure({
			abr: {
				enabled: false
			}
		});

		// Download all tracks
		const tracks = player.getVariantTracks();
		window.localStorage.setItem('tracks', JSON.stringify(tracks));

		if (tracks.length > 0) {
			// Get the largest track
			const highest = tracks.reduce((a, b) => (a.width > b.width ? a : b));
			player.selectVariantTrack(highest, true);
		}
	};

	const onLoadedData = (event) => {
		event.persist();

		setIsReady(true)(stateDispatch);

		// Set video session interval
		const sessionIntervalTime = calculateVideoIntervalTime(videoSession);

		setSessionIntervalTime(sessionIntervalTime)(stateDispatch);
	};

	const handlePlay = () => {
		if (videoRef.current.paused) {
			videoRef.current.play();
		}

		setIsPaused(false)(stateDispatch);
	};

	const handlePause = () => {
		videoRef.current.pause();

		setIsPaused(true)(stateDispatch);
	};

	const togglePlay = () => {
		state.isPaused ? handlePlay() : handlePause();
	};

	const onEnded = (event) => {
		event.persist();
		setIsEnded(true)(stateDispatch);
		setIsPaused(true)(stateDispatch);

		areAnimationsAvailable && !isActive && nextProduct();
	};

	const playerInitialization = () => {
		const {
			playlistData: {
				drm,
				sources: { HLS, DASH }
			}
		} = playlistData;

		const manifestUri = getManifestUri({ isSafari, hls: HLS, dash: DASH });

		const configuration = getPlayerConfiguration(drm);

		const player = (playerRef.current = new shaka.Player(videoRef.current));

		player.configure(configuration);
		player.load(manifestUri).then(onLoad).catch(onError);
	};

	const handleClearIntervals = () => {
		setSessionIntervalTime(null)(stateDispatch);
	};

	const handleUpdateVideoSession = async () => {
		updateVideoSession(videoSessionId);
	};

	useEffect(() => {
		playlistData && playerInitialization();

		return () => {
			const player = playerRef.current;

			if (player) {
				player.removeEventListener(ERROR, onError);
				player.removeEventListener(BUFFERING, onBufferStateChange);
				player.removeEventListener('play', handlePlay);
				player.destroy();
			}
		};

		// eslint-disable-next-line
	}, [playlistData]);

	useEffect(() => {
		playlist && setPlaylistData(playlist)(stateDispatch);
		// eslint-disable-next-line
	}, [playlist]);

	useEffect(() => {
		areAnimationsAvailable ? handlePlay() : handlePause();
	}, [areAnimationsAvailable]);

	useEffect(() => {
		/* PAUSING AFTER SCROLLED PAGE UNDER VIDEO CONTAINER */
		const observer = new IntersectionObserver((entries) =>
			entries.forEach((entry) => !entry.isIntersecting && handlePause())
		);

		videoRef.current && observer.observe(videoRef.current);

		return () => {
			// Clear intervals
			handleClearIntervals();

			videoSessionId && deleteVideoSession(videoSessionId);

			observer.disconnect();
		};
		// eslint-disable-next-line
	}, []);

	// ---- hook to manage the interval ----
	useInterval(handleUpdateVideoSession, sessionIntervalTime || null);

	return (
		<PlayerContext.Provider
			value={{ ...state, isMuted, togglePlay, toggleSound }}
		>
			<Video
				ref={videoRef}
				autoPlay={areAnimationsAvailable}
				muted={isMuted}
				isReady={state.isReady}
				onLoadedData={onLoadedData}
				onEnded={onEnded}
				playsInline
			/>
			{children}
		</PlayerContext.Provider>
	);
};

PlayerProvider.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	data: object.isRequired,
	nextProduct: func.isRequired,
	isMuted: bool.isRequired,
	isActive: bool.isRequired,
	deleteVideoSession: func.isRequired,
	updateVideoSession: func.isRequired,
	toggleSound: func.isRequired
};

export default PlayerProvider;
